import { useEffect, useContext, useState } from "react";
import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";
import GlobalContext from "GlobalContext";


export default function InvestmentProjects({ title , appDisclaimer , pageTitle, ExportChartFootnote, menuItemsStrings}) {
  useEffect(() => {
    document.title = `${pageTitle} - ${title}`;
  }, [pageTitle,title]);

  const { app } = useContext(GlobalContext);
  const [err, setError] = useState(null);
  const [FilterHeightInvestmentProjects, setFilterHeight] = useState(688);

  useEffect(() => {
    app.model.waitForOpen.promise.then(() => {
      setTimeout(async () => {
        app.model.enigmaModel.evaluate("$(=count({1}distinct [_col_id_investmentprojects]))").then(async (reply) => {
          const InvestmentProjectsNumberOfFilterRows = reply*27+40;
          console.log('InvestmentProjectsNumberOfFilterRows/Number of rows: ', InvestmentProjectsNumberOfFilterRows);
          setFilterHeight(InvestmentProjectsNumberOfFilterRows);
        }).catch(err => {
          if(!err) setError(err);
        });
      }, 500);
    });
  }, [app, err])

  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            <h6 className="page-disclaimer"> {appDisclaimer} </h6>
            {/* <QlikObject
              className="page-title"
              id="ANJWBAj"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          {/* <div className="col-md-2 col-sm-3 col-2">
            <QlikObject
              id="VXWetj"
              height="30px"
              showLoader={false}
            />
          </div> */}
        </div>
      </div>
          
      {/* <div className="row justify-content-start">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="a49c567e-5174-4fbc-8463-a0e7a6a4ce20"
              height="30px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="NYxkAp"
              height="40px"
              showLoader={false}
            />
          </div>
      </div> */}

      <div className="container-fluid">

        <div className="row justify-content-start">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">

              <div className="row justify-content-left">
                {/* KPIs Row */}

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: No of investment projects */}
                      <QlikCard
                        id="77e52859-5a8b-44ac-9011-32dceb4edb82"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: Budget */}
                      <QlikCard
                        id="23778a9d-3e86-4fc6-8eb7-6340cb9417e5"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: No of disposers */}
                      <QlikCard
                        id="94028dca-e4b9-492c-a32b-8e1986ae4b21"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: Financial coverage */}
                      <QlikCard
                        id="c1e74b08-58c7-49b0-8fbe-e12db05de5bd"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

              </div>


              <div className="row justify-content-left">
                {/* Charts Row */}

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  {/* pie chart column */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* pie chart */}
                      <QlikCard
                        id="QWmuVb"
                        // height="383px"
                        //height="max(calc(100vh - 365px)/2 - 24px, 320px)"
                        height="min(calc(78vh - 263px), calc(320px + 38px))"
                        showHeader={true}
                        showMaximize={false}
                        showExport={true}
                        showPdfExport
                        showImageExport={true}
                        exportImageWidth={1280}
                        exportImageHeight={720}
                        onBeforeExportHandler={async ({ vis, exportType }) => {
                          //console.log(data);
                          console.log(vis);
                          if(exportType in { pdf: true, image: true} && vis)
                            await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                        }}
                        onAfterExportHandler={async ({ vis, exportType }) => {
                          console.log('Export type: ', exportType);
                          if(exportType in { pdf: true, image: true})
                            vis.setOptions({ showTitles: false, footnote:"" });
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>
{/*                   <div className="row justify-content-center">
                    
                      <div className="col-12 col-xl-6">
                          <QlikObject
                            id="mjNLYGe"
                            height="32px"
                            showHeader={false}
                            showMaximize={false}
                            showExport={false}
                          />
                      </div>
                  </div> */}

                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  {/* barchart column */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* barchart */}
                      <QlikCard
                        id="UKGBpf"
                        // height="383px"
                        //height="max(calc(100vh - 365px)/2 - 24px, 320px)"
                        height="min(calc(78vh - 263px - 36px), 320px)"
                        showHeader={true}
                        showMaximize={false}
                        showExport={true}
                        showPdfExport
                        showImageExport={true}
                        exportImageWidth={1280}
                        exportImageHeight={720}
                        onBeforeExportHandler={async ({ vis, exportType }) => {
                          //console.log(data);
                          console.log(vis);
                          if(exportType in { pdf: true, image: true} && vis)
                            await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                        }}
                        onAfterExportHandler={async ({ vis, exportType }) => {
                          console.log('Export type: ', exportType);
                          if(exportType in { pdf: true, image: true})
                            vis.setOptions({ showTitles: false, footnote:"" });
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    {/* indicator selection for barchart */}
                      <div className="col-12 col-xl-6">
                          <QlikObject
                            id="TDzmYP"
                            height="36px"
                            showHeader={false}
                            showMaximize={false}
                            showExport={false}
                          />
                      </div>
                  </div>
               

                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  {/* placeholder chart column */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* placeholder chart */}
                      <QlikCard
                        id="dPAwmG"
                        // height="383px"
                        //height="max(calc(100vh - 365px)/2 - 24px, 320px)"
                        height="min(calc(78vh - 263px), calc(320px + 38px))"
                        showHeader={true}
                        showMaximize={false}
                        showExport={true}
                        showPdfExport
                        showImageExport={true}
                        exportImageWidth={1280}
                        exportImageHeight={720}
                        onBeforeExportHandler={async ({ vis, exportType }) => {
                          //console.log(data);
                          console.log(vis);
                          if(exportType in { pdf: true, image: true} && vis)
                            await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                        }}
                        onAfterExportHandler={async ({ vis, exportType }) => {
                          console.log('Export type: ', exportType);
                          if(exportType in { pdf: true, image: true})
                            vis.setOptions({ showTitles: false, footnote:"" });
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>
{/*                   <div className="row justify-content-center">
                      <div className="col-12 col-xl-6">
                          <QlikObject
                            id="BKqEtg"
                            height="32px"
                            showHeader={false}
                            showMaximize={false}
                            showExport={false}
                          />
                      </div>
                  </div> */}
                  
               

                </div>

              </div>

              <div className="row justify-content-left">
                {/* Table Row */}

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  {/* straight table */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* table */}
                      <QlikCard
                        id="7f4f2a58-5305-49af-af0e-686fc319ce5a"
                        // height="383px"
                        //height="max(calc(100vh - 365px)/2, 150px)"
                        height="max(calc(100vh - 365px)/2, 380px)"
                        showHeader={true}
                        showMaximize={true}
                        showExport={true}
                        onBeforeExportHandler={async ({  }) => {
                          //console.log(data);
                            await app.variable.setStringValue('export_link', '1');
                        }}
                        onAfterExportHandler={async ({  }) => {
                            app.variable.setStringValue('export_link', '0');
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>

                </div>

              </div>

            </div>


            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  {/* FilterPane */}
                  <QlikCard
                    id="5cf85d9d-f680-4c2b-b6be-67e42e2aa726"
                    // height="485px"
                    //height={`min(336px, calc(100vh - max(min(${FilterHeightInvestmentProjects}px - 25px -  min(calc(3.5vh + 2px), calc(100vh - 581px - 20px -  min(calc(3.5vh + 2px))), 184px) + 10px - 267px))`}
                    //height="calc(min(calc(78vh - 263px), calc(320px + 38px)) + 90px - 40px)"
                    //height="min(calc(78vh - 263px + 90px - 12px), calc(320px + 38px + 90px - 12px))"
                    height="min(calc(78vh - 263px + 90px + 8px + 18.8px + 4px), calc(320px + 38px + 90px + 8px + 18.8px + 4px))"
                    showHeader={false}
                    showMaximize={false}
                    showExport={false}
                    showOverlayer={false}
                  />
                  <div className="row justify-content-start">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <QlikObject
                        id="gvmSghm"
                        // height="485px"
                        height="min(calc(10.5vh + 2px), 64px)"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>
                  </div>
                  {/* Columns Selector */}
                  <QlikCard
                    id="24bec433-a8a2-429d-ab52-20a9e2f8ead5"
                    // height="485px"
                    //height="max(calc(100vh - 557px), 100px)"
                    //height={`max(min(${FilterHeightInvestmentProjects}px - 25px, calc(100vh - 581px - 20px)), 184px)`} //557
                    //height="max(calc(100vh - 365px)/2 + 40px - min(calc(3.5vh + 2px), 32px), 396px - min(calc(3.5vh + 2px), 32px))"
                    height="max(calc(100vh - 365px)/2 - min(calc(10.5vh + 2px), 64px) - 18.8px - 2px, 380px - min(calc(10.5vh + 2px), 64px) - 18.8px - 2px)"
                    showHeader={false}
                    showMaximize={false}
                    showExport={false}
                    showOverlayer={false}
                  />

            </div>

          </div>

          {/* <div className="row mb-2">
            <div className="col-md-2 col-sm-3 col-2">
              <QlikObject
                id="b4231dd2-2ce3-4d63-b394-838d3c6f0b0e"
                height="0px"
                showLoader={false}
              />
            </div>
          </div> */}
          
        </div>
    </section>
  );
}
