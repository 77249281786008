import { useEffect, useContext, useState } from "react";
import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";
import GlobalContext from "GlobalContext";


export default function InvestmentProjectsLocations({ title , appDisclaimer , pageTitle, ExportChartFootnote, menuItemsStrings}) {
  useEffect(() => {
    document.title = `${pageTitle} - ${title}`;
  }, [pageTitle,title]);

  const { app } = useContext(GlobalContext);
  const [err, setError] = useState(null);
  const [FilterHeightApplications, setFilterHeight] = useState(417);

  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            <h6 className="page-disclaimer"> {appDisclaimer} </h6>
            {/* <QlikObject
              className="page-title"
              id="ANJWBAj"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          {/* <div className="col-md-2 col-sm-3 col-2">
            <QlikObject
              id="VXWetj"
              height="30px"
              showLoader={false}
            />
          </div> */}
        </div>
      </div>
          
      {/* <div className="row justify-content-start">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="a49c567e-5174-4fbc-8463-a0e7a6a4ce20"
              height="30px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="NYxkAp"
              height="40px"
              showLoader={false}
            />
          </div>
      </div> */}

      <div className="container-fluid">

        <div className="row justify-content-start">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">

              <div className="row justify-content-left">
                {/* KPIs Row */}

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: No of investment projects */}
                      <QlikCard
                        id="d0aff906-69d5-424e-978b-89b813ed1bfa"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: Budget */}
                      <QlikCard
                        id="e6fe2982-9edf-4b50-8852-a21754a1de84"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: Commited funding */}
                      <QlikCard
                        id="214b2971-9a99-4143-b963-2e4ac0242158"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: Financial coverage */}
                      <QlikCard
                        id="ea0a88c6-b33e-44b6-9259-6db40c4f495a"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

              </div>

              <div className="row justify-content-left">
                {/* Buttons Row */}

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* Button: All projects */}
                      <QlikCard
                        id="fuYWAT"
                        height="48px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* Button: One region */}
                      <QlikCard
                        id="VUPLYEd"
                        height="48px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* Button: multiple regions */}
                      <QlikCard
                        id="UFGaJJ"
                        height="48px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* Button: Whole country */}
                      <QlikCard
                        id="eSmq"
                        height="48px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

              </div>

              <div className="row justify-content-left">
                {/* Charts Row */}
{/*                 {menuItemsStrings.containerTabSelectorGeo != '3' ?
                        <h1>{menuItemsStrings.containerTabSelectorProjects} {menuItemsStrings.containerTabSelectorProjects.length}</h1>
                        :
                        <h1>{menuItemsStrings.containerTabSelectorProjects} {menuItemsStrings.containerTabSelectorProjects ? 'defined' : 'not defined'}</h1>
                }  */}
                {menuItemsStrings.containerTabSelectorGeo != '3' ? (
                  <>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                      {/* container column */}
                          {/* container */}
                          
                          
                          <QlikCard
                            id="EThuWPP"
                            // height="383px"
                            height="max(calc(100vh - 365px - 50px), 200px)"
                            showHeader={true}
                            showMaximize={false}
                            showExport={menuItemsStrings.containerTabSelectorGeo != '1' && menuItemsStrings.containerTabSelectorGeo != '2'}
                            showPdfExport
                            showImageExport={true}
                            exportImageWidth={1280}
                            exportImageHeight={720}
                            onBeforeExportHandler={async ({ vis, exportType }) => {
                              //console.log(data);
                              console.log(vis);
                              if(exportType in { pdf: true, image: true} && vis)
                                await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                            }}
                            onAfterExportHandler={async ({ vis, exportType }) => {
                              console.log('Export type: ', exportType);
                              if(exportType in { pdf: true, image: true})
                                vis.setOptions({ showTitles: false, footnote:"" });
                            }}
                            exportText = {menuItemsStrings.exportText}
                            exportCompletedText = {menuItemsStrings.exportCompletedText}
                            closeBtnText = {menuItemsStrings.closeBtnText}
                          />

                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                      {/* container column */}
                          {/* container */}
                          <QlikCard
                            id="MPmThDU"
                            // height="383px"
                            height="max(calc(100vh - 365px - 50px), 200px)"
                            showHeader={true}
                            showMaximize={false}
                            showExport={true}
                            showPdfExport={false}
                            showImageExport={false}
                            exportImageWidth={1280}
                            exportImageHeight={720}
                            onBeforeExportHandler={async ({ vis, exportType }) => {
                              //console.log(data);
                              console.log(vis);
                              if(exportType in { pdf: true, image: true} && vis)
                                await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                            }}
                            onAfterExportHandler={async ({ vis, exportType }) => {
                              console.log('Export type: ', exportType);
                              if(exportType in { pdf: true, image: true})
                                vis.setOptions({ showTitles: false, footnote:"" });
                            }}
                            exportText = {menuItemsStrings.exportText}
                            exportCompletedText = {menuItemsStrings.exportCompletedText}
                            closeBtnText = {menuItemsStrings.closeBtnText}
                          />

                    </div>
                    </>
                ) : ( 
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      {/* container column */}
                          {/* container */}
                          <QlikCard
                            id="hUUtV"
                            // height="383px"
                            height="max(calc(100vh - 365px - 50px), 200px)"
                            showHeader={true}
                            showMaximize={false}
                            showExport={false}
                            showPdfExport={false}
                            showImageExport={true}
                            exportImageWidth={1280}
                            exportImageHeight={720}
                            onBeforeExportHandler={async ({ vis, exportType }) => {
                              //console.log(data);
                              console.log(vis);
                              if(exportType in { pdf: true, image: true} && vis)
                                await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                            }}
                            onAfterExportHandler={async ({ vis, exportType }) => {
                              console.log('Export type: ', exportType);
                              if(exportType in { pdf: true, image: true})
                                vis.setOptions({ showTitles: false, footnote:"" });
                            }}
                            exportText = {menuItemsStrings.exportText}
                            exportCompletedText = {menuItemsStrings.exportCompletedText}
                            closeBtnText = {menuItemsStrings.closeBtnText}
                          />

                    </div>
                )}

              </div>

            </div>


            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  {/* FilterPane */}
                  <QlikCard
                    id="34226c9b-7174-4542-815b-ba485d5919d1"
                    // height="485px"
                    height="max(calc(100vh - 365px + 90px), 200px)"
                    showHeader={false}
                    showMaximize={false}
                    showExport={false}
                    showOverlayer={false}
                  />

            </div>

          </div>

          {/* <div className="row mb-2">
            <div className="col-md-2 col-sm-3 col-2">
              <QlikObject
                id="b4231dd2-2ce3-4d63-b394-838d3c6f0b0e"
                height="0px"
                showLoader={false}
              />
            </div>
          </div> */}
          
        </div>
    </section>
  );
}
